import React from 'react';
function downloadCV() {
    const link = document.createElement('a');
   
    link.download = '/magdalinivitsioti.pdf'; // Το όνομα του αρχείου που θα κατέβει
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
function About() {
  return (
    <section id="about" className="about-section">
      <h2>About</h2>
      <p>Hi, I’m Magda, an Applied Informatics student who loves using technology to solve everyday problems. I’ve picked up skills in a bunch of programming languages and development techniques, and I’m always excited to turn what I learn into something real. I’m also a bit of a tech enthusiast, always keeping an eye on the latest trends and figuring out how to grow and improve.</p>
     {/* Button for downloading CV */}
     <a href="/magdalinivitsioti.pdf"  download="/magdalinivitsioti.pdf" class="download-button" onClick="ga('send', 'event', 'CV', 'download', 'Magda Vitsioti CV');">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 4.5-15 15m0 0h11.25m-11.25 0V8.25" />
  </svg>
  Download Resume
</a>


    </section>
  );
}

export default About;
