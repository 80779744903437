

import React, { useEffect, useState } from 'react';

function Hero() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true); // Ενεργοποιούμε το fade-in για τα κείμενα
    }, 600); // Καθυστέρηση 500ms για ομαλή εμφάνιση
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="hero-section" id='home'>
      <div className={`hero-text ${isVisible ? 'show' : ''}`}>
      <h1 id='h1'>MAGDA VITSIOTI</h1>
      <p id='p'>Software Engineer</p>
     
      </div>
       <a href="#about" className="hero-arrow">
      <i className="fas fa-arrow-down"></i>
      </a>
    </section>
  );
}

export default Hero;
