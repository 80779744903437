import React from 'react';
import Skill from './Skill';

function Skills() {
  const categories = {
    "Programming Languages": ["Java", "C", "Python"],
    "Web Development": ["HTML5", "CSS", "JavaScript", "Node.js","React"],
    "Tools & Platforms": ["GitHub", "Google Cloud Platform", "Jira","Tableau","Microsoft Office"],
    "Other Skills": ["Data Analysis", "SQL","MongoDB", "Algorithms", "First Aid (BLS, CPR/AED)"]
  };

  return (
    <section id="skills" className="skills-section">
      <h2>Skills</h2>
      
      <div className="categories-container">
        {Object.keys(categories).map((category, index) => (
          <div key={index} className="category-circle">
            <h3>{category}</h3>
            <div className="skills-bubbles">
              {categories[category].map((skill, index) => (
                <Skill key={index} skillName={skill} />
              ))}
            </div>
          </div>
        ))}
      </div>
      
    </section>
  );
}

export default Skills;
