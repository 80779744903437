import { useEffect } from "react";

function Education() {
  const educationData = [
    {
      institution: "University of Macedonia",
      degree: "Bachelor in Applied Informatics",
      year: "2021 - Present",
      description: "Focused on core topics such as programming, databases, web development, and data analysis. Acquired strong skills in software development, algorithm design, and information systems management, along with hands-on experience in practical projects.",
    },
    {
      institution: "Athens University of Economics and Business",
      degree: "Data Analysis and Programming with Python 3",
      year: "2023",
      description: "Completed a three-month intensive training program on Python programming and data analysis. Gained hands-on experience with Python libraries for data manipulation, including Pandas and NumPy, to solve real-world problems.",
    },
    {
        institution: "Saint Paul High School",
        degree: "High School Diploma",
        year: "2021",
        description: "Graduated with honors, achieving a final grade of 19.8/20. Gained foundational knowledge in mathematics, sciences, and computer literacy, paving the way for higher education in informatics.",
      },
  ];

  useEffect(() => {
    const items = document.querySelectorAll('.timeline-item');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    items.forEach((item) => observer.observe(item));
  }, []);

  return (
    <section id="education" className="education-section">
      <h2>Education</h2>
      <div className="timeline">
        {educationData.map((edu, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-icon"></div>
            <div className="timeline-content">
              <h3>{edu.institution}</h3>
              <h4>{edu.degree}</h4>
              <span className="timeline-year">{edu.year}</span>
              <p>{edu.description}</p>
            </div>
          </div>
        ))}
      </div>
      
    </section>
  );
}

export default Education;