import React from 'react';

function Skill({ skillName }) {
  return (
    <li className="skill-bubble">
      {skillName}
    </li>
  );
}

export default Skill;
