import React from 'react';
import Project from './Project';

function Projects() {
  const projects = [
    { title: 'Green Hero', description: 'I had the privilege of contributing to the development of Green Hero app. The Green Hero is an Android app designed to promote sustainability and recycling. Its purpose is to encourage users to recycle by giving them rewards. ', link: 'https://github.com/vtmag/Green-Hero', name:" Green-Hero" },
    { title: 'sofiadavi.gr', description: 'Development of a personal website for a psychologist, using modern front-end technologies such as HTML, CSS, and JavaScript. The website is currently under construction and is temporarily hosted on GitHub, with plans to be deployed live soon.', link: 'https://github.com/vtmag/sofia-davi-react', name:" sofia-davi-react" },
    { title: 'Task Manager', description: 'Developed a Task Management Website to enhance web development skills, allowing users to efficiently manage tasks and stay organized. Focused on delivering a seamless user experience across devices.', link: 'https://github.com/vtmag/Task-Management-Website',name:" Task-Management-Website" }
  ];

  return (
    <section id="projects" className="projects-section">
    <h2>My Projects</h2>
    <div className="projects-grid">
      {projects.map((project, index) => (
        <div key={index} className="project-card">
          <h3>{project.title}</h3>
          <p>{project.description}</p>
          <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">
              <i className="fab fa-github"></i>{project.name}
            </a>
        </div>
      ))}
    </div>
    
  </section>
  );
}

export default Projects;
