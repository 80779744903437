import React from 'react';

function Contact() {
  return (
    <section id="contact" className="contact-section">
      <h2>Contact Me</h2>
      <p id='pi'>Let's work together or just connect. Feel free to reach out:</p>
      
      <div className="contact-info">
        <div className="contact-item">
          <i className="fas fa-envelope"></i>
          <a href="mailto:magdavitsioti@gmail.com">magdavitsioti@gmail.com</a>
        </div>
        <div className="contact-item">
          <i className="fas fa-phone"></i>
          <a href="tel:+306906912321">+30 6906912321</a>
        </div>
        <div className="contact-item">
          <i className="fas fa-map-marker-alt"></i>
          <p >Thessaloniki, Greece</p>
        </div>
      </div>

      <div className="social-media">
        <a href="https://github.com/vtmag" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-github"></i> 
        </a>
        <a href="https://www.linkedin.com/in/magda-vitsioti-b2902b252/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin"></i> 
        </a>
      </div>
    </section>
  );
}

export default Contact;
