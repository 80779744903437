import React, { useState, useEffect } from 'react';
import './App.css';
import Hero from './Hero';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import Education from './Education';
import Skills from './Skills';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      duration: 1500 // Προσαρμογή του χρόνου (1500ms)
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !document.querySelector('.fixed-nav').contains(event.target) &&
        !document.querySelector('.burger-menu').contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
  

  return (
    <div className="App">
      {/* Burger Menu for small screens */}
      <div className={`burger-menu ${isOpen ? 'open' : ''}`}>
        <div className="burger-icon" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        
        {isOpen && <div className="overlay show"></div>}
        
        
          <nav className={`mobile-nav ${isOpen ? 'open' : ''}`}>
            <ul>
            {['Home', 'About', 'Education', 'Skills', 'Projects', 'Contact'].map((item, index) => (
              <li key={index} style={{ '--i': index + 1 }}>
                <a href={`#${item.toLowerCase()}`} onClick={toggleMenu}>
                  {item}
                </a>
              </li>
            ))}
          </ul>
          <div className="footer-section">
          <div className="social-icons2">
            <a href="https://www.facebook.com/magda.vits/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.linkedin.com/in/magda-vitsioti-b2902b252/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://github.com/vtmag" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-github"></i>
            </a>
          </div>

       

          <div className='rights'>
            <p>&copy; 2024 Magda Vitsioti. All rights reserved</p>
          </div>
        </div>
            
          </nav>
          
        
         
      </div>

      {/* Fixed Menu for larger screens */}
      <nav className="fixed-nav">
        <ul>
          <li><a href="#home" onClick={toggleMenu}>Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#education">Education</a></li>
          <li><a href="#skills">Skills</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>

      {/* Main Content */}
      <main>
        <Hero />
        <About />
        <Education />
        <Skills />
        <Projects />
        <Contact />
      </main>

      {/* Back to Top Button */}
      {showButton && (
        <button className="back-to-top" onClick={scrollToTop}>
          <i className="fas fa-arrow-up"></i>
        </button>
      )}

      {/* Footer */}
      <footer>
        <p>© 2024 Magda Vitsioti. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
